<template>
  <v-card>
    <v-card-title>
      <span class="headline">Relacionar columnas (notas y estados)</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-col>
          <span
            >* Relacionar las columnas de notas selecciónado la columna a
            relacionar en frente de cada item
          </span>
        </v-col>
        <v-row v-for="(i, y) in associate" v-bind:key="i[y]">
          <v-col class="col-4">
            <span class="ml-md"> {{ i["Nota"] }} </span>
          </v-col>
          <v-col class="col-8">
            <v-autocomplete
              v-if="associate.length != 0"
              dense
              :items="colsExtra"
              @input="add(y, i['Anotation'])"
              v-model="i['Anotation']"
              item-text="nombre"
              item-value="nombre"
              :append-icon="colsExtra.length == 0 ? 'mdi-reload' : ''"
              clearable
              hide-details
              label="Lista de notas adicionales"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="close">Cerrar</v-btn>
      <v-btn color="primary" :disabled="isGuardando" text @click="saveRelation"
        >Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
  
  <script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "ToolBarAssociate",
  props: [
    "colsExtra", // las despues de Total
    "notas", // cantidad de notas del curso
    "materia",
    "periodo",
    "listN", // lista de relacion guardada
  ],

  data() {
    return {
      isGuardando: false,
      associate: [],
    };
  },

  mounted() {
    this.associate = [];
    if (this.listN.length != 0) {
      for (let n = 0; n < this.notas.length; n++) {
        var not = this.notas[n];
        // splitNot numero de la nota
        var splitNot = not.split(" ")[1];
        // notListN los values del this.listN que estan en un object Anotation y Nota
        var notListN = Object.values(this.listN);
        var tieneRelacion = notListN.find((e) => e.Nota == splitNot);
        if (tieneRelacion) {
          this.associate.push({
            Nota: "Nota " + splitNot,
            Anotation: tieneRelacion["Anotation"],
          });
        } else {
          this.associate.push({
            Nota: "Nota " + splitNot,
            Anotation: null,
          });
        }
      }
    }
    if (this.listN.length == 0) {
      for (let n = 0; n < this.notas.length; n++) {
        this.associate.push({
          Nota: "Nota " + (n + 1),
          Anotation: null,
        });
      }
    }
  },

  methods: {
    add(i, data) {
      this.associate[i]["Anotation"] = data;
    },
    saveRelation() {
      var sendData = [];
      for (let f = 0; f < this.associate.length; f++) {
        const element = this.associate[f];
        if (element["Anotation"]) {
          var sp = element["Nota"].split(" ");
          sendData.push({ Nota: sp[1], Anotation: element["Anotation"] });
        }
      }
      this.isGuardando = true;
      console.log(sendData);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "editar_relacion_notas");
          fd.append("materia", this.materia);
          fd.append("periodo", this.periodo);
          fd.append("data", JSON.stringify(sendData)); // cambia pass profesor
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data;
            this.isGuardando = false;
            if (data.text == "OK") {
              this.$emit("associate-cols", "refresh");
            } else {
              this.$alert(
                "No se pudo realizar la relación entre notas y columnas"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
    close() {
      this.$emit("associate-cols", "close");
    },
  },
};
</script>
  
  <style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>